module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-use-query-params-virtual-5bd2b19419/0/cache/gatsby-plugin-use-query-params-npm-1.0.1-878a7051ae-38e9a6bc14.zip/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-intl-virtual-533fd2db30/0/cache/gatsby-plugin-intl-npm-0.3.3-abe5cffb01-f3af1b2593.zip/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/i18n","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-4ed4678a84.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat","Montserrat:bold"]}},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-google-tagmanager-virtual-bf49035b6d/0/cache/gatsby-plugin-google-tagmanager-npm-3.0.0-64afe83085-76f3ef7aa7.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PMMKSGQ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-segment-js-npm-3.6.0-71cb89ebee-c854dd9c56.zip/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"BVEG6hRfQO3ovqQuGHdTNuLvwz7ooNBb","devKey":"F613vHof9BAyrh3yHyzr6AloTx0KskIH","trackPage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
