// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-palette-tsx": () => import("./../../../src/pages/palette.tsx" /* webpackChunkName: "component---src-pages-palette-tsx" */),
  "component---src-templates-company-page-tsx": () => import("./../../../src/templates/companyPage.tsx" /* webpackChunkName: "component---src-templates-company-page-tsx" */),
  "component---src-templates-grt-100-list-page-tsx": () => import("./../../../src/templates/grt100ListPage.tsx" /* webpackChunkName: "component---src-templates-grt-100-list-page-tsx" */)
}

